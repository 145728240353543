<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div :class="{ 'col-12': isClientChild || !hasCustomersRole, 'col-8': !isClientChild }">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>クーポン名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="shopName"
                                  v-maxlength
                                  maxlength="45"
                                  v-trim
                                  v-model="searchForm.shopName"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>発行元</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="issuer"
                                  v-model="searchForm.issuer"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in issuerTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>公開・非公開</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="validFlag"
                                  v-model="searchForm.validFlag"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in validFlagTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>ステータス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="timePublic"
                                  v-model="searchForm.timePublic"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in expiredTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>配布済・未配布</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="isDistribute"
                                  v-model="searchForm.isDistribute"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in distributionTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4" v-if="!isClientChild && hasCustomersRole">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:headline>CSVで対象を指定</template>
              <template v-slot:body>
                <div class="d-flex justify-content-center align-items-center">
                  <ActionButton class="btn btn-lg btn-black" :disabled="isImportDisabled" :handle-submit="handleUploadCsv" button-text="CSVアップロード" />
                  <input
                    type="file"
                    style="display: none"
                    ref="csvInput"
                    accept=".csv"
                    @change="onCsvFilePickedCoupon"
                  />
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :storeModule="storeModule"
                    :labels="labels"
                    :data-table="dataTable"
                    :isDisabledCheckAll="allInvalid"
                    @handle-toggle="handleStatus"
                    @select-items="handleSelectedItems"
                    ref="table"
                    :count="couponCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :listLength="couponCount"
                  :searchConditions="searchConditions"
                >
                </PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter v-if="!isClientChild">
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item" v-if="hasCustomersRole">
            <button :disabled="isImportDisabled" class="btn btn-green" type="button" @click="showModalDistribution">
              個別配布
            </button>
          </li>
          <li class="listGrid-item pos-end" v-if="hasShopRole">
            <router-link class="btn btn-main" :to="{ name: 'EventCouponRegister' }">新規作成</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('statusModal', toggleFlag)" v-if="statusModal">
      <template v-slot:headline>{{ isPublic ? '公開' : '非公開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            isPublic
              ? 'このクーポンを公開しますか？'
              : 'このクーポンを非公開にしますか？'
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="cancelChangeStatus"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="isPublic ? '公開する' : '非公開にする'"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <DistributionDialog @on-reset-checkbox="onResetCheckbox" @handleImportDisabled="handleImportDisabled" :isOpen.sync="distributionModal" :searchCoupon="searchCoupon" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import nav from '@/mixins/nav/coupon-event';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import pickFileCsv from '@/mixins/plugin/pickFileCsv';
import download from '@/mixins/plugin/download';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';
import DistributionDialog from './_DistributionDialog.vue'
//constants
import { CouponConstants } from '@/constants/coupon';
//helpers
import { addThreeDotEndLine, breakLineWithComma } from '@/helpers/formatData';

export default {
  name: 'EventCoupon',
  data: function() {
    return {
      pageName: 'クーポン',
      modulePath: 'coupon/getEventCoupons',
      importCsvAction: 'coupon/importCouponCsv',
      importingItem: 'IMPORTING_COUPON',
      importStatusAction: 'shop/getImportStatus',
      storeModule: 'coupon',
      searchFields: ['name', 'shopName', 'issuer', 'validFlag', 'timePublic', 'isDistribute'],
      searchConditions: {},
      statusModal: false,
      distributionModal: false,
      couponId: null,
      shopId: null,
      isPublic: 0,
      selectedItems: {
        allFlag: false,
        id: [],
      },
      isImportDisabled: false,
      issuerTypes: Object.values(CouponConstants.issuerType),
      validFlagTypes: Object.values(CouponConstants.validFlagType),
      expiredTypes: Object.values(CouponConstants.expiredType),
      distributionTypes: Object.values(CouponConstants.distributionType),
    };
  },
  components: {
    FormRow,
    TableWithCheckbox,
    PaginationLog,
    Modal,
    DistributionDialog,
  },
  mixins: [nav, modal, search, pickFileCsv, download],
  computed: {
    ...mapGetters({
      lists: 'coupon/couponList',
      couponCount: 'coupon/couponCount',
      allInvalid: 'coupon/allInvalid',
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      hasCustomersRole: 'auth/hasCustomersRole',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    labels() {
      return [
        ...(!this.isClientChild && this.hasCustomersRole) ? [{ isCheckbox: true }] : [],
        { key: 'image', name: '画像' },
        { key: 'name', name: 'クーポン名', tdClass: 'dataTable-break w-145' },
        { key: 'issuer', name: '発行元' },
        { key: 'shopName', name: '店舗名', notShow: !this.hasShopRole, tdClass: 'dataTable-break w-145' },
        { key: 'totalCoupon', name: '発行枚数' },
        { key: 'distributionCoupon', name: '配布数' },
        { key: 'count', name: '利用回数' },
        { key: 'validFlag', name: '公開' },
        { key: 'timePublic', name: 'ステータス', tdClass: 'dataTable-break' },
        ...!this.isClientChild ? [{ isButtonColumn: true }] : [],
      ];
    },
    dataTable() {
      return (
        this.lists &&
        this.lists.map((item) => {
          return {
            ...item,
            isCheckboxDisabled: item?.isExpired || !item?.typeCoupon || !item?.validFlag || item?.remaining === 0 || item?.isGreyout || item?.isLimitDistribute,
            isRowDisabled: item?.isGreyout,
            image: {
              type: 'image',
              value: item.image?.url,
            },
            name: addThreeDotEndLine(item.name, 15),
            shopName: addThreeDotEndLine(item.shop?.name, 15),
            totalCoupon: item?.remaining === null ? '-' : item.totalCoupon,
            count: item.count || '-',
            timePublic: breakLineWithComma(item?.status),
            validFlag: {
              isToggleButton: true,
              value: item.validFlag,
              id: item.id,
              shopId: item.shop?.id,
              isDisabled: this.isClientChild
            },
            buttonLinks: [
              {
                content: '編集',
                class: 'btn btn-sm btn-bd-main',
                routeName: 'EventCouponEdit',
                params: { id: item?.id, shopId: item.shop?.id },
                isNotShown: this.isClientChild
              },
            ],
          };
        })
      );
    },
    searchCoupon() {
      return {
        target: this.selectedItems.id,
        allFlag: this.selectedItems.allFlag,
        searchConditions: {
          ...this.initialSearchConditions,
          ...this.searchForm,
          ...JSON.parse(sessionStorage.getItem('searchConditions')),
        }
      }
    }
  },
  methods: {
    handleImportDisabled(val) {
      this.isImportDisabled = val
    },
    toggleFlag() {
      this.lists.find((el) => el.id === this.couponId).validFlag = !this.isPublic;
    },
    handleStatus(id, newStatus, shopId) {
      this.showModal('statusModal');
      this.couponId = id;
      this.shopId = shopId;
      this.lists.find((el) => el.id === id).validFlag = newStatus;
      this.isPublic = newStatus ? 1 : 0;
    },
    async changeStatus() {
      const result = await this.$store.dispatch('coupon/updateCouponStatus', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        id: this.couponId,
        data: { status: this.isPublic },
      });
      this.closeModal('statusModal');
      if (result) this.$message.updated('coupon');
      else this.toggleFlag();
    },
    cancelChangeStatus() {
      this.handleStatus(this.couponId, !this.isPublic);
      this.closeModal('statusModal');
    },
    handleSelectedItems(nVal) {
      this.selectedItems = {
        allFlag: nVal.allFlag,
        id: nVal.ids,
      };
    },
    checkSelectedCoupon(messageKey) {
      let isValid = false
      if (!this.selectedItems.allFlag && !this.selectedItems.id.length) {
        this.$message.showError(messageKey);
      } else if (this.allInvalid) {
        this.$message.showError('noSelectedOpenCoupon');
      } else {
        isValid = true
      }
      return isValid
    },
    showModalDistribution() {
      if (this.checkSelectedCoupon('noSelectedCouponForIssuer')) {
        this.distributionModal = true;
      }
    },
    handleUploadCsv() {
      if (this.checkSelectedCoupon('noSelectedCouponCsv')) {
        this.uploadCsv();
      }
    },
    onResetCheckbox(data) {
      if (data) {
        this.$refs.table.resetCheck();
        this.$refs.pagination.resetPagination();
      }
    },
    onCsvFilePickedCoupon(event) {
      this.onCsvFilePicked(event, 'noCountCouponCsv', {
        searchCoupon : {
          target: this.selectedItems.id,
          searchCondition: this.searchConditions,
          allFlag: this.selectedItems.allFlag
        }
      });
    }
  },
};
</script>
