<template>
  <div class="form-multiselect" :class="{ 'is-error': isError, 'custom-form-multiselect': isCustom }">
    <Multiselect
      :options="options"
      v-model="selected"
      :multiple="multiple"
      :disabled="disabled"
      :selectLabel="multiselectSetting.selectLabel"
      :deselectLabel="multiselectSetting.deselectLabel"
      :selectedLabel="multiselectSetting.selectedLabel"
      :placeholder="multiselectSetting.placeholder"
      :close-on-select="closeOnSelect"
      :track-by="singleLabel || defaultLabel"
      :custom-label="getLabel || getDefaultLabel"
      @input="handleChangeSelection"
      @search-change="debouncedHandleChangeSearch"
      :options-limit="9999"
    >
      <template slot="noResult">
        <span>{{ multiselectSetting.noDataLabel }}</span>
      </template>
      <template slot="noOptions">
        <span>{{ multiselectSetting.noDataLabel }}</span>
      </template>
      <template slot="option" slot-scope="props">
        <span :class="{ 'option-child': props.option.isChild }">
          {{ props.option.isChild ? "> " : ""}}
          {{ props.option.name }}
        </span>
      </template>
    </Multiselect>
  </div>
</template>

<script>
//https://vue-multiselect.js.org/#sub-getting-started
//https://qiita.com/nkk777dev/items/ad2bc84237d8fe8097ab
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { debounce } from 'lodash';

const defaultSettings = {
  placeholder: 'テキストを入力して検索',
  selectLabel: '',
  deselectLabel: '',
  selectedLabel: '',
  noDataLabel: '対象データがありません',
};

export default {
  data: function() {
    return {
      selected: null,
      multiselectSetting: undefined,
      defaultLabel: 'id',
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    singleLabel: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    getLabel: {
      type: Function,
    },
    initialValue: {},
    searchKeyMinLength: {
      type: Number,
    },
    searchAction: {
      type: String,
    },
    resetOptions: {
      type: String,
    },
    params: {
      type: Object,
      default: () => {},
    },
    searchField: {
      type: String,
    },
    isError: {
      type: Boolean,
    },
    isCustom: {
      type: Boolean,
      default: false
    },
    hasAllOption: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.multiselectSetting = Object.assign(
      {},
      defaultSettings,
      this.multiselectSetting
    );
    this.selected = this.initialValue;
    this.debouncedHandleChangeSearch = debounce(this.handleChangeSearch, 1500);
  },
  components: {
    Multiselect,
  },
  watch: {
    initialValue(value) {
      if (value) {
        this.selected = value;
      }
    },
    selected(value) {
      let valueLength = this.multiple ? value?.length : value;
      if (!valueLength && this.resetOptions) {
        this.$store.commit(this.resetOptions);
      }
      if (this.multiple && value?.some(e => e.id === 0)) {
        this.options?.slice(1).forEach(option => {
          option.$isDisabled = true;
        });
      } else {
        this.options.forEach(option => {
          option.$isDisabled = false;
        });
      }
    },
  },
  methods: {
    handleChangeSelection(value) {
      if (this.hasAllOption && value?.some(e => e.id === 0)) value = value.filter(e => e.id === 0);
      this.$emit('change-selection', value);
    },
    resetSelection() {
      this.selected = null;
    },
    getDefaultLabel({ name }) {
      return name;
    },
    handleChangeSearch(query) {
      if (query.length >= this.searchKeyMinLength) {
        this.$store.dispatch(this.searchAction, {
          ...this.params,
          limit: 9999,
          [this.searchField]: query,
        });
      } else {
        if (this.closeOnSelect) {
          this.$store.commit(this.resetOptions);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.option-child {
  padding-left: 15px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

