<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row" v-if="!isGmoOrOfficeLoginStoreAndStore">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <SearchSelect
                                ref="searchSelect"
                                :options="shopList"
                                :closeOnSelect="true"
                                :searchKeyMinLength="searchKeyLength"
                                :params="params"
                                :searchAction="searchAction"
                                :resetOptions="resetOptions"
                                :searchField="searchField"
                                @change-selection="handleSelectedShop"
                              />
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="$permission.isGmoOrOffice() && !isGmoOrOfficeLoginStoreAndStore">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗グループ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="groupId"
                                  v-model="searchForm.groupId"
                                >
                                  <option value="">選択してください</option>
                                  <option
                                    v-for="item in groupList"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>支払サイクル</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="paymentCycle"
                                  v-model="searchForm.paymentCycle"
                                >
                                  <option value="">選択してください</option>
                                  <option
                                    v-for="item in paymentCycles"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>売上日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>売上総額</template>
                  <template v-slot:num>{{ paymentAmount | comma }}</template>
                  <template v-slot:sup>円</template>
                  <template v-if="isBalanceShown" v-slot:sub><span class="numDisplay-sub-sup">{{ useTicketFlag ? 'TICKET残高' : 'チャージ残高' }}</span>{{ balance | comma }}円</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :data-table="dataTable"
                    :labels="labels"
                    :storeModule="storeModule"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="shopAggregateCount"
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                  :subdomain="subdomain"
                  :noGetPaginationList="noGetPaginationList"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!shopAggregateList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
import { RoleConstants } from '@/constants/role';
//mixin
import flatpickr from '@/mixins/plugin/flatpickr';
import sort from '@/mixins/plugin/sort';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import history from '@/mixins/module/history';
import nav from '@/mixins/nav/summary';
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload'
//helpers
import { formatLocalString, endline } from '@/helpers/formatData';
import { CommonConstants } from '@/constants/common';

export default {
  name: 'EventSummary',
  data: function() {
    return {
      pageName: '集計',
      searchConditions: {},
      searchFields: ['shopId', 'groupId', 'paymentCycle', 'startDate', 'endDate'],
      normalModulePath: 'aggregate/getShopAggregate',
      ticketModulePath: 'aggregate/getShopAggregateTicket',
      normalCSVPath: 'aggregate/downloadShopAggregateList',
      ticketCSVPath: 'aggregate/downloadShopAggregateListTicket',
      searchAction: 'shop/getShopList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      searchField: 'name',
      storeModule: 'aggregate',
      storedField: 'idRequestExportSummary',
    };
  },
  components: {
    FormRow,
    NumDisplay,
    Flatpickr,
    SearchSelect,
    TableWithCheckbox,
    PaginationLog,
    ModalConfirmDownload,
  },
  mixins: [nav, flatpickr, sort, search, download, history],
  computed: {
    ...mapGetters({
      paymentCycles: 'common/paymentCycles',
      groupList: 'group/groupList',
      shopList: 'shop/shopList',
      shopAggregateList: 'aggregate/shopAggregateList',
      shopAggregateCount: 'aggregate/shopAggregateCount',
      childEventAggregate: 'aggregate/childEventAggregate',
      userInfo: 'auth/infor',
      paymentTotal: 'aggregate/paymentTotal',
      hasTransactionRole: 'auth/hasTransactionRole',
      hasShopRole: 'auth/hasShopRole',
      isClientChild: 'auth/isClientChild',
      isShowCurrency: 'event/isShowCurrency',
      hasCurrency: 'event/hasCurrency',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeUrl: 'common/isGmoOrOfficeUrl',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
      useTicketFlag: 'event/useTicketFlag',
      viewAllhistory: 'event/viewAllhistory',
    }),
    paymentAmount() {
      return this.isGmoOrOfficeLoginStoreAndStoreGroup ? this.paymentTotal.amount : this.childEventAggregate.payments.amount;
    },
    paymentAmountMoney() {
      return this.isGmoOrOfficeLoginStoreAndStoreGroup ? this.paymentTotal.amountMoney : this.childEventAggregate.payments.amountMoney;
    },
    balance() {
      return this.childEventAggregate.charges.charged - this.childEventAggregate.payments.amountMoney - this.childEventAggregate.payments.expired - this.childEventAggregate.payments.cancel;
    },
    subdomain() {
      if (this.isGmoOrOfficeLoginStore) return this.newSubdomain;
      return this.$permission.isStoreOrGroup() ? this.userInfo.event?.subdomain : this.$route.params.subdomain;
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    params() {
      return {
        status: StatusConstants.shop.approved.value,
        withTrashed: 1,
        subdomain: this.subdomain,
        directory: this.$route.params.directory
      }
    },
    isBtnDisabled() {
      return !this.hasTransactionRole ? 'btn-disabled' : '';
    },
    isCheckRoleShop() {
      const isClientChildNoShopRole = this.isClientChild && !this.userInfo?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE);
      const isClientNoShopRole = this.$permission.isOffice() && !this.userInfo?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE);
      return isClientChildNoShopRole || (isClientNoShopRole && !this.isShowCurrency);
    },
    labels() {
      if (this.useTicketFlag) {
        return [
          { key: 'shopName', name: '店舗名', tdClass: 'pre-line mw-110' },
          { key: 'quantityPayment', name: '利用枚数' },
          { key: 'amount', name: '利用金額' },
          { key: 'quantityCancel', name: '返金枚数' },
          { key: 'cancel', name: '返金額' },
          { key: 'revenue', name: '売上金額' },
          { key: 'quantityCharge', name: '付与枚数', notShow: !this.hasCurrency },
          { key: 'amountCharge', name: '付与金額', notShow: !this.hasCurrency },
          { key: 'quantityExpired', name: '有効期限切れ枚数', notShow: !this.hasCurrency },
          { key: 'expired', name: '有効期限切れ金額', notShow: !this.hasCurrency },
          { isButtonColumn: true, name: '内訳' },
        ];
      }
      return [
        { key: 'shopName', name: '店舗名', tdClass: 'pre-line mw-110' },
        { key: 'count', name: '利用件数' },
        { key: 'amount', name: '利用金額' },
        { key: 'cancel', name: '返金額' },
        { key: 'revenue', name: '売上金額' },
        { key: 'chargeCPM', name: '付与金額', notShow: !this.hasCurrency },
        { key: 'expired', name: '有効期限切れ金額', notShow: !this.hasCurrency },
        { isButtonColumn: true, name: '内訳' },
      ]
    },
    dataTable() {
      return this.shopAggregateList.map((item) => {
        const data = {
          ...item,
          shopName: endline(item.shopName, 15),
          amount: '¥' + formatLocalString(item.amount),
          cancel: '¥' + formatLocalString(item.cancel),
          amountCharge: '¥' + formatLocalString(item.amountCharge),
          revenue: '¥' + formatLocalString(Number.parseInt(item.amount) - Number.parseInt(item.cancel || 0)),
          chargeCPM: '¥' + formatLocalString(item.chargeCPM),
          expired: '¥' + formatLocalString(item.expired),
          buttonLinks: [
            {
              content: "支払履歴",
              class: `btn-bd-main ${this.isBtnDisabled}`,
              routeName: this.isGmoOrOfficeLoginStoreAndStoreGroup ? "ShopHistory" : "EventHistoryUse",
              params: {
                subdomain: this.subdomain,
                startDate: this.payloadSearchSummary.startDate,
                endDate: this.payloadSearchSummary.endDate,
                shopDetail: { id: item.shopId, name: item.shopName },
                groupId: this.payloadSearchSummary.groupId,
                paymentCycle: this.payloadSearchSummary.paymentCycle,
                shop: this.payloadSearchSummary.shop,
                type: CommonConstants.TYPE_HISTORY_USE.UPDATED_DATE,
              }
            },
          ],
        };
        return data;
      });
    },
    isBalanceShown() {
      return !this.isGmoOrOfficeLoginStoreAndStoreGroup && (!this.isClientChild || this.viewAllhistory);
    }
  },
  methods: {
    handleSelectedShop(list) {
      this.searchForm.shopId = list.map(item => item.id);
      this.searchForm.shop = JSON.parse(JSON.stringify(list));
    },
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch(this.downloadCSVPath, {
        ...this.payloadDownloadCsv,
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
    getGroupList() {
      if ((!this.isGmoOrOfficeLoginStoreAndStoreGroup) && this.hasShopRole) {
        this.$store.dispatch('group/getGroupList', { limit: 9999 });
      }
    },
  },
  mounted () {
    const loading = this.$loading.show();
    if (this.$route.params?.shopDetail) {
      this.searchForm = this.$route.params;
      if (this.$route.params?.shop?.length > 0) {
        this.$refs.searchSelect.selected = this.searchForm.shop.map(el => {
          return {
            id: el.id,
            name: el.name,
          }
        });
        this.handleSelectedShop(this.searchForm.shop);
      }
      setTimeout(() => {
        this.search();
      }, 300)
    }

    Promise.all([
      this.$store.dispatch('common/getPaymentCycles'),
      this.getGroupList(),
      this.$store.dispatch('aggregate/getPaymentAggregate', { subdomain: this.subdomain, isCheckRoleShop: this.isCheckRoleShop ? 1 : 0 }),
      this.$store.dispatch('aggregate/getChildEventAggregate', { subdomain: this.subdomain, isCheckRoleShop: this.isCheckRoleShop ? 1 : 0 }),
    ])
    .finally(() => {
      this.$loading.clear(loading);
    });
  },
};
</script>
