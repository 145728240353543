<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>{{ qrId ? '編集' : '新規登録' }}</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>ポイントカテゴリーの選択</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-select">
                            <select
                              class="form-control form-select-input"
                              :class="{ 'is-error': errors.pointCategoryId }"
                              name="pointCategoryId"
                              v-model="form.pointCategoryId"
                            >
                              <option value="" selected>選択してください</option>
                              <option
                                v-for="item in categoriesList"
                                :key="item.id"
                                :value="item.id"
                              >{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>管理用タイトル</template>
                      <template v-slot:labelNote>（20文字以内）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            v-trim
                            v-maxlength
                            maxlength="20"
                            class="form-control"
                            :class="{ 'is-error': errors.title }"
                            type="text"
                            name="title"
                            placeholder=""
                            v-model="form.title"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>発行元店舗の選択</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div v-if="!qrId" class="row">
                            <SearchSelect
                              ref="searchSelect"
                              singleLabel="value"
                              :isError="errors.shopId"
                              :options="shopPointList"
                              :multiple="false"
                              :closeOnSelect="true"
                              :searchKeyMinLength="searchKeyLength"
                              :searchAction="searchActionShopPoint"
                              :resetOptions="resetOptionShopPoint"
                              :params="shopParams"
                              :searchField="searchField"
                              @change-selection="handleSelectedShop"
                            />
                          </div>
                          <div v-else class="row">
                            <div class="col-12">{{ form.shopName }}</div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>発行ポイント数</template>
                      <template v-slot:content>
                        <div v-if="!qrId" class="form-content-row">
                          <ul class="listGrid">
                            <li v-for="item in issuingTypes" :key="item.value" class="listGrid-item">
                              <label class="form-radio form-radio-btn">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  name="type"
                                  :value="item.value"
                                  v-model="form.type"
                                />
                                <span :class="{ 'form-radio-label': true, 'is-error': errors.type }">{{ item.label }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div v-else class="row">
                          <div class="col-12">{{ form.typeName }}</div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors.amount }"
                                type="tel"
                                v-number="'integer'"
                                name="amount"
                                placeholder=""
                                v-model="form.amount"
                              />
                            </div>
                            <div class="form-group-item">ポイント</div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>利用制限</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <ul class="listGroup">
                            <li class="listGroup-item">
                              <label class="form-radio">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  :value="true"
                                  name="limitOfUse"
                                  v-model="form.limitOfUse"
                                />
                                <span class="form-radio-label">あり</span>
                              </label>
                              <transition name="fade">
                                <ul class="listGroup" v-show="form.limitOfUse">
                                  <li class="listGroup-item listGroup-point">
                                    <p class="form-label">ワンタイムトークン</p>
                                  </li>
                                  <li class="listGroup-item listGroup-point">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="useOneTimeToken"
                                        v-model="form.useOneTimeToken"
                                        :disabled="canEditOneTimeToken"
                                        @change="handleUseOneTimeToken"
                                      />
                                      <span class="form-check-label">ワンタイムトークンの利用</span>
                                    </label>
                                  </li>
                                  <li class="listGroup-item listGroup-point">
                                    <p class="form-label">QR発行制限</p>
                                  </li>
                                  <li class="listGroup-item listGroup-point">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="useQuantityFlag"
                                        v-model="form.useQuantityFlag"
                                        :disabled="!!isUseQuantityFlag"
                                      />
                                      <span :class="{ 'form-check-label': true }">利用可能枚数の設定</span>
                                    </label>
                                    <transition name="fade">
                                      <div
                                        v-show="form.useQuantityFlag"
                                        class="form-content-row listGroup-item-child"
                                      >
                                        <div class="form-group">
                                          <div class="form-group-item">
                                            <label v-if="!!isUseQuantityFlag">{{ pointQrDetail.quantity }}</label>
                                            <input v-else
                                              class="form-control"
                                              :class="{ 'is-error': errors.quantity }"
                                              type="tel"
                                              v-number="'integer'"
                                              name="quantity"
                                              v-model="form.quantity"
                                            />
                                          </div>
                                          <div class="form-group-item">枚</div>
                                        </div>
                                      </div>
                                    </transition>
                                  </li>
                                  <li class="listGroup-item listGroup-point">
                                    <p class="form-label">QR顧客利用制限</p>
                                  </li>
                                  <li v-for="item in limitTypes" :key="item.value" class="listGroup-item listGroup-point">
                                    <label class="form-radio">
                                      <input
                                        class="form-radio-input"
                                        type="radio"
                                        name="limitType"
                                        v-model="form.limitType"
                                        :value="item.value"
                                      />
                                      <span :class="{ 'form-radio-label': true, 'is-error': errors.limitType }">{{ item.label }}</span>
                                    </label>
                                    <transition name="fade">
                                      <div
                                        v-show="item.value === limitTypes.timesLimit.value && isTimesLimit"
                                        class="form-content-row listGroup-item-child"
                                      >
                                        <div class="form-group">
                                          <div class="form-group-item">
                                            <input
                                              class="form-control"
                                              :class="{ 'is-error': errors.numberTimesLimit }"
                                              type="tel"
                                              v-number="'integer'"
                                              name="numberTimesLimit"
                                              placeholder=""
                                              v-model="form.numberTimesLimit"
                                            />
                                          </div>
                                          <div class="form-group-item">回/同一ユーザー</div>
                                        </div>
                                      </div>
                                    </transition>
                                  </li>
                                  <li class="listGroup-item listGroup-point">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="expiredTime"
                                        v-model="form.expiredTime"
                                        :value="true"
                                      />
                                      <span :class="{ 'form-check-label': true, 'is-error': errors.expiredTime }">利用可能の期間設定</span>
                                    </label>
                                    <transition name="fade">
                                      <div class="form-content-row listGroup-item-child" v-show="hasExpiredTime">
                                        <DateRange
                                          field="useDate"
                                          :value="form.useDate"
                                          :errorField="errors.useDate"
                                          @on-change="onChangeDateRange"
                                        />
                                      </div>
                                    </transition>
                                  </li>
                                  <li class="listGroup-item listGroup-point">
                                    <label class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="useKeyword"
                                        v-model="form.useKeyword"
                                        @change="handleUseKeyword"
                                      />
                                      <span :class="{ 'form-check-label': true }">認証キーワードの設定</span>
                                    </label>
                                    <transition name="fade">
                                      <div
                                        v-show="form.useKeyword"
                                        class="form-content-row listGroup-item-child"
                                      >
                                        <div class="form-group">
                                          <input
                                            class="form-control"
                                            :class="{ 'is-error': errors.keyword }"
                                            type="text"
                                            v-hiragana
                                            v-trim
                                            maxlength="8"
                                            name="keyword"
                                            v-model="form.keyword"
                                          />
                                        </div>
                                        <p class="listGrid-item p-0">
                                          ※認証キーワードを設定する場合は、2文字以上8文字以内の<br/>
                                          ひらがなにて設定してください。
                                        </p>
                                      </div>
                                    </transition>
                                  </li>
                                </ul>
                              </transition>
                            </li>
                            <li class="listGroup-item">
                              <label class="form-radio">
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  :value="false"
                                  name="limitOfUse"
                                  v-model="form.limitOfUse"
                                  :disabled="isUseQuantityFlag || canEditOneTimeToken"
                                />
                                <span class="form-radio-label">なし</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: listRouteName }">キャンセル</router-link>
          </li>
          <li class="listGrid-item" v-if="!isClientChild">
            <ActionButton class="btn btn-main" :handle-submit="handleSubmit" :button-text="buttonText" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { isAlphaHiraganaWithMinMaxLength } from '@/helpers/validators';
//mixins
import nav from '@/mixins/nav/point';
import error from '@/mixins/plugin/error';
//components
import FormRow from '@/components/FormRow.vue';
import DateRange from '@/components/DateRange.vue';
import SearchSelect from '@/components/SearchSelect.vue';

export default {
  data: function() {
    return {
      pageName: 'QRコード',
      form: {
        useDate: {
          start: '',
          end: '',
        },
      },
      issuingTypes: {
        add: { label: "付与（加算）", value: 0 },
        use: { label: "消費（減算）", value: 1 },
      },
      limitTypes: {
        timesLimit: { label: "1QR当り利用制限", value: "timesLimit" },
        oneTimePerDay: { label: "1ユーザー1日1回のみ", value: "oneTimePerDay" },
      },
      prefixs: ['useDate'],
      searchField: 'shopId'
    }
  },
  mixins: [nav, error],
  components: {
    FormRow,
    DateRange,
    SearchSelect
  },
  validations() {
    let form = {
      pointCategoryId: { selectRequired: required },
      title: { required, maxLength: maxLength(20) },
      shopId: { selectRequired: required },
      type: { selectRequired: required },
      amount: { required, between: between(1, 999999) },
      quantity: {},
      numberTimesLimit: {},
      useDate: {},
      keyword: {}
    }
    if (this.form?.useQuantityFlag && this.form?.limitOfUse) {
      form.quantity = { required, between: between(1, 1000000000) };
      if (!this.pointQrDetail?.quantity && this.form?.quantity && this.pointQrDetail?.totalUsed && (Number(this.form.quantity) <= Number(this.pointQrDetail?.totalUsed))) {
        form.quantity = { quantityMoreThanTotalUsed: () => false };
      }
    }
    if (this.isTimesLimit) {
      form.numberTimesLimit = { required, between: between(1, 999) };
      if (this.form?.useQuantityFlag && this.form.quantity && (Number(this.form.numberTimesLimit) > Number(this.form.quantity))) {
        form.numberTimesLimit = { numberTimesLimitWithMaxValue: () => false };
      }
    }
    if (this.hasExpiredTime ) {
      form.useDate = {
        start: { selectRequired: required },
        end: { selectRequired: required },
      }
    }
    if (this.form?.useKeyword && this.form?.limitOfUse) {
      form.keyword = { required, alphaHiraganaWithMinMaxLength: isAlphaHiraganaWithMinMaxLength(2, 8) };
    }
    return { form };
  },
  computed: {
    ...mapGetters({
      hasShopRole: 'auth/hasShopRole',
      shopPointList: 'parentPoint/shopPointList',
    }),
    isTimesLimit() {
      return this.form.limitOfUse && this.form.limitType === this.limitTypes.timesLimit.value;
    },
    isTimesPerDay() {
      return this.form.limitOfUse && this.form.limitType === this.limitTypes.oneTimePerDay.value;
    },
    hasExpiredTime() {
      return this.form.limitOfUse && this.form.expiredTime;
    },
    qrId() {
      return this.$route.params.id;
    },
    listRouteName() {
      return this.isPointCommon ? 'EventParentPointQR' : 'EventChildPointQR';
    },
    isUseQuantityFlag() {
      return !!this.qrId && !!this.pointQrDetail?.useQuantityFlag && !!this.pointQrDetail?.quantity;
    },
    buttonText() {
      return this.qrId ? '編集する' : '登録する'
    },
    canEditOneTimeToken() {
      return this.qrId && !this.pointQrDetail?.canEditOneTimeToken;
    }
  },
  mounted() {
    const loading = this.$loading.show();
    this.initForm();
    Promise.all([
      this.getCategories(),
      this.getQRDetail(),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
  methods: {
    initForm() {
      this.form = {
        shopId: null,
        pointCategoryId: "",
        title: "",
        type: this.issuingTypes.add.value,
        amount: null,
        limitOfUse: false,
        limitType: this.limitTypes.timesLimit.value,
        numberTimesLimit: null,
        quantity: null,
        keyword: null,
        expiredTime: false,
        useQuantityFlag: false,
        useOneTimeToken: false,
        useKeyword: false,
        useDate: {
          start: '',
          end: '',
        },
      }
    },
    async getQRDetail() {
      if (this.qrId) {
        const pointQRDetail = await this.$store.dispatch(`${this.storeModule}/getPointQRDetail`, {
          id: this.qrId,
          subdomain: this.subdomain
        });
        const { shop, useEndDate, useStartDate, ...form} = pointQRDetail;
        this.form = {
          ...form,
          shopId: shop?.id || 0,
          limitType: pointQRDetail.oneTimePerDay ? this.limitTypes.oneTimePerDay.value : this.limitTypes.timesLimit.value,
          typeName: Object.values(this.issuingTypes).find(item => item.value === pointQRDetail.type).label,
          useDate: {
            ...this.form.useDate,
            start: useStartDate,
            end: useEndDate,
          },
          shopName: shop?.name || '事務局'
        }
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'pointQR');
        return false;
      } else {
        let { useDate, numberTimesLimit, ...payload } = this.form;
        const actionPath = this.qrId ? 'updatePointQR' : 'createPointQR';
        const result = await this.$store.dispatch(`${this.storeModule}/${actionPath}`, {
          ...payload,
          subdomain: this.subdomain,
          numberTimesLimit: this.isTimesLimit ? numberTimesLimit : 0,
          oneTimePerDay: this.isTimesPerDay ? true : false,
          expiredTime: this.hasExpiredTime ? true : false,
          useStartDate: this.hasExpiredTime ? useDate.start : '',
          useEndDate: this.hasExpiredTime ? useDate.end : '',
        });
        if (result) {
          const actionName = this.qrId ? 'updated' : 'created';
          await this.$router.push({ name: this.listRouteName });
          this.$message[actionName]('pointQR');
        }
      }
    },
    onChangeDateRange(field, type, val) {
      this.form[field] = {
        ...this.form[field],
        [type]: val,
      }
    },
    handleSelectedShop(item) {
      this.form.shopId = item?.value;
    },
    handleUseOneTimeToken(e) {
      if (this.form.useKeyword || (this.qrId && this.pointQrDetail?.usedKeywordBefore)) {
        if (e.target.checked) {
          this.$message.showError(this.qrId && this.pointQrDetail?.usedKeywordBefore ? 'notChangeKeywordToOtp' : 'notOnUseKeywordAndOtp');
          e.target.checked = false;
          this.form.useOneTimeToken = false;
        } else {
          this.$message.reset();
        }
      }
    },
    handleUseKeyword(e) {
      if (this.form?.useOneTimeToken || (this.qrId && this.pointQrDetail?.usedOTPBefore)) {
        if (e.target.checked) {
          this.$message.showError(this.qrId && this.pointQrDetail?.usedOTPBefore ? 'notChangeOtpToKeyword' : 'notOnUseKeywordAndOtp');
          e.target.checked = false;
          this.form.useKeyword = false;
        } else {
          this.$message.reset();
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.listGroup {
  padding-left: 30px;
  &-item-child {
    margin-left: 15px;
  }
}
.listGroup-item {
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.listGroup-item .form-radio,
.listGroup-item .form-check {
  display: inline-flex;
  margin-bottom: 15px;
}
.form-group-item input {
  width: 80px;
}
</style>
